import { validation } from '@nurse-senka/nurse-senka-frontend-sdk';
import { z } from 'zod';

import { mbStrLen } from '../utils/mbString';
import { symbolIncludes } from '../utils/validation';

import type { SchoolUpdaterRequest } from '@nurse-senka/nurse-senka-web-ui';

export const schoolTypeIds = [
  // eslint-disable-next-line no-magic-numbers
  1, 2, 3, 4, 5,
] as const;

export type SchoolTypeId = (typeof schoolTypeIds)[number];

const schoolTypeLabels = [
  '大学',
  '短期大学',
  '専門学校',
  '准看護学校',
  'その他',
] as const;

export type SchoolTypeLabel = (typeof schoolTypeLabels)[number];

export type SchoolType = {
  id: SchoolTypeId;
  label: SchoolTypeLabel;
};

export const schoolTypes: SchoolType[] = schoolTypeIds.map((value, index) => ({
  id: value,
  label: schoolTypeLabels[index],
}));

export const defaultSchoolTypeId: SchoolType = {
  id: 1,
  label: '大学',
};

export const isSchoolTypeId = (value: unknown): value is SchoolTypeId => {
  if (typeof value !== 'number') {
    return false;
  }

  return schoolTypeIds.includes(value as SchoolTypeId);
};

export type GraduationYear = number;

const startGraduationYear = (): GraduationYear =>
  // eslint-disable-next-line no-magic-numbers
  new Date().getFullYear();

const createEndGraduationYear = () =>
  // eslint-disable-next-line no-magic-numbers
  startGraduationYear() + 4;

export const createGraduationYearList = (): GraduationYear[] => {
  const startYear = startGraduationYear();

  const endYear = createEndGraduationYear();

  const birthYearList = [];

  // eslint-disable-next-line id-length, no-plusplus
  for (let i = startYear; i <= endYear; i++) {
    birthYearList.push(i);
  }

  return birthYearList;
};

export const isGraduationYear = (value: unknown): value is GraduationYear => {
  if (typeof value !== 'number') {
    return false;
  }

  return value >= startGraduationYear() && value <= createEndGraduationYear();
};

// 登録時とは違い更新時はNG（ナース専科就職ナビ）との同時登録を意識する必要はないので卒業年のリストは広い範囲とする
export const createUpdatedGraduationYearList = () => {
  const startYear = 1970;

  // eslint-disable-next-line no-magic-numbers
  const endYear = new Date().getFullYear() + 10;

  const birthYearList = [];

  // eslint-disable-next-line id-length, no-plusplus
  for (let i = startYear; i <= endYear; i++) {
    birthYearList.push(i);
  }

  return birthYearList;
};

export const validateSchoolName = (value: string) => {
  const length = mbStrLen(value);

  // eslint-disable-next-line no-magic-numbers
  if (length < 1 || length > 100) {
    return false;
  }

  if (symbolIncludes(value)) {
    const pattern = /[()]/u;

    // 学校名に `(` もしくは `)` が含まれる場合があるので、それらは許容する
    return pattern.test(value);
  }

  return true;
};

export type SchoolName = string;

export type FetchSchoolNameSuggestionsParams = {
  schoolName: SchoolName;
};

export type FetchSchoolNameSuggestions = (
  schoolName: SchoolName,
) => Promise<string[]>;

export type ChangeSchoolRequest = SchoolUpdaterRequest;

export const changeSchoolRequestSchema = z.object({
  schoolName: z
    .string()
    .refine(
      (value) => validateSchoolName(value),
      '卒業学校/卒業予定学校は全角で入力して下さい。',
    ),
  schoolTypeId: z
    .number()
    .refine(
      (value) => isSchoolTypeId(value),
      '学校種別を正しく選択して下さい。',
    ),
  graduationYear: z.number(),
});

export const isChangeSchoolRequest = (
  value: unknown,
): value is ChangeSchoolRequest =>
  validation(changeSchoolRequestSchema, value).isValidate;

export type ChangeSchool = (request: ChangeSchoolRequest) => Promise<void>;
